(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 480;
  window.MOBILE_WIDTH = 768;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.TABLET_WIDTH = 1024;
  window.DESKTOP_WIDTH = 1240;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  window.IS_PRINT = $BODY.hasClass('print');
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  window.WINDOW_HEIGHT = $WINDOW.height();
  window.HEADER_HEIGHT = $HEADER.outerHeight();
  window.HEADER_MENU_HEIGHT = $('.header-menu-container').outerHeight();

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= MOBILE_WIDTH;
  }
  window.IS_LANDSCAPE_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask('+7 (999) 999-99-99', {
    placeholder: "+7 (___) ___-__-__"
  });


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Social window open
    // ------------------
    .on('click.social-window-open', '.header-social-container a, .footer-social-container a, .contact-social-container a', function(e) {
      e.preventDefault();

      var socialWin;

      if( socialWin = window.open($(this).prop('href'), '_blank', 'chrome=1,centerscreen=1,dialog=1,modal=0,close=1,directories=0,titlebar=1,toolbar=1,location=1,status=1,menubar=0,scrollbars=1,resizable=1,top=120,left=30,width=1040,height=600')) {
        socialWin.focus();
      }
    });


  if( !IS_MOBILE_WIDTH() ) {
    $WINDOW
      // Header menu fixed for tablet and desktop
      // ----------------------------------------
      .on('scroll', function(e) {
        if ( $WINDOW.scrollTop() >= (HEADER_HEIGHT - HEADER_MENU_HEIGHT) ) {
          if( !$HEADER.hasClass('header-fixed') )
            $HEADER.css('padding-bottom', Math.ceil(HEADER_MENU_HEIGHT)).addClass('header-fixed');
        } else {
          $HEADER.css('padding-bottom', 0).removeClass('header-fixed');
        }
      })
      .trigger('scroll');
  }
  else {
    // Header menu button
    // ------------------
    $MENU_CHECKBOX
      .on('change', function() {
        if( this.checked ) {
          $BODY.addClass('menu-is-opened');
        } else {
          $BODY.removeClass('menu-is-opened');
        }
      })
      .prop('checked', false)
      .prop('disabled', false)
      .trigger('change');

    $DOCUMENT
      .ready(function() {
        // Padding for fixed header
        // ------------------------
        $HEADER.css('padding-top', Math.ceil($('.header-content').outerHeight())).addClass('header-fixed');

        // Move header social to menu container
        // ------------------------------------
        if( IS_MOBILE_WIDTH() )
          $('.header-social-container').appendTo('.header-menu-container > .wrapper > ul').addClass('initialized');
      })

      // Close header menu
      // -----------------
      .on('click.close-header-menu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu-container ul').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.header-menu', '.header-menu-container div > a, .header-menu-container div > span, .header-menu-container .dropmarker', function(e) {
        e.preventDefault();

        var $self = $(this),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered');

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( e.target.nodeName.toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-hovered');
          } else {
            $parent.addClass('is-hovered');
          }
        } else {
          if( e.target.nodeName.toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  // Main rotator
  $('.main-rotator-container').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    arrows: false,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendDots: '.main-rotator-dots'
  }));
})();
